<template>
  <div id="app">
    <HeaderBlock v-if="!sticky" />
    <HeaderBlockFixed v-if="sticky" />
    <MainBlock />
    <block1 />
    <div id="what">
      <block2 />
      <block3 />
    </div>
    <div id="why">
      <block4 />
      <block5 />
    </div>
    <div id="do">
      <block6 />
    </div>
    <block7 v-if="step == 0" @nextStep="step = 1" />
    <div class="step-1">
      <testItem v-if="step == 1 && answer == false" @answer="setAnswer" :step="step" @close="closeTest">
        <template v-slot:title>
          Сексуальным <br>домогательствам <br>подвергаются <br>женщины, которые <br>откровенно одеты <br>и вызывающе себя <br>ведут.
        </template>
      </testItem>
      <TestAnswer v-if="answer == true && step == 1 && answerText == 1" :step="step" @close="closeTest" @next="goToStep(2)">
        <template v-slot:title>
          Нет, это миф
        </template>
        <template v-slot:text>
          По данным L’Oreal Paris, 84% женщин подвергались сексуализированому насилию <br> в общественных местах. Сложно представить, что все они были откровенно одеты и вели себя вызывающе. Но даже если так — эти женщины тоже имеют право на безопасность <br> и отсутствие вмешательства в их жизнь. <span class="just-desktop">Важно помнить, что цель агрессора — не желание секса, а установление власти и контроля, поэтому его жертвой может стать любой человек вне зависимости от того, как он выглядит, каким образом общается и поступает с другими людьми.</span>
        </template>
        <template v-slot:question>
          Сексуальным <br>домогательствам <br>подвергаются <br>женщины, которые <br>откровенно одеты <br>и вызывающе себя <br>ведут.
        </template>
      </TestAnswer>
      <TestAnswer v-if="answer == true && step == 1 && answerText == 0" :step="step" @close="closeTest" @next="goToStep(2)">
        <template v-slot:title>
          Вы правы
        </template>
        <template v-slot:text>
          По данным L’Oreal Paris, 84% женщин подвергались сексуализированому насилию <br> в общественных местах. Сложно представить, что все они были откровенно одеты и вели себя вызывающе. Но даже если так — эти женщины тоже имеют право на безопасность <br> и отсутствие вмешательства в их жизнь. <span class="just-desktop">Важно помнить, что цель агрессора — не желание секса, а установление власти и контроля, поэтому его жертвой может стать любой человек вне зависимости от того, как он выглядит, каким образом общается и поступает с другими людьми.</span>
        </template>
        <template v-slot:question>
          Сексуальным <br>домогательствам <br>подвергаются <br>женщины, которые <br>откровенно одеты <br>и вызывающе себя <br>ведут.
        </template>
      </TestAnswer>
    </div>
    <div class="step-2">
      <testItem v-if="step == 2 && answer == false" @answer="setAnswer" :step="step" @close="closeTest">
        <template v-slot:title>
          Если женщина<br/> не пресекает домогательства, значит, ей это нравится.
        </template>
      </testItem>
      <TestAnswer v-if="answer == true && step == 2 && answerText == 1" :step="step" @close="closeTest" @next="goToStep(3)">
        <template v-slot:title>
          Нет, это не так
        </template>
        <template v-slot:text>
          Существует множество причин,<br/> по которым жертва сексуальных домогательств не может ответить обидчику. Прежде всего,<br/> это ощущение страха. Жертва никогда не знает, услышит ли агрессор слово «нет», как воспримет его и не перейдет ли к более серьезным действиям. Поэтому полезно, чтобы свидетели были обучены правильной реакции на харассмент и поддерживали пострадавшую.
        </template>
        <template v-slot:question>
          Если женщина<br/> не пресекает домогательства, значит, ей это нравится.
        </template>
      </TestAnswer>
      <TestAnswer v-if="answer == true && step == 2 && answerText == 0" :step="step" @close="closeTest" @next="goToStep(3)">
        <template v-slot:title>
          Конечно, это миф
        </template>
        <template v-slot:text>
          Существует множество причин,<br/> по которым жертва сексуальных домогательств не может ответить обидчику. Прежде всего,<br/> это ощущение страха. Жертва никогда не знает, услышит ли агрессор слово «нет», как воспримет его и не перейдет ли к более серьезным действиям. Поэтому полезно, чтобы свидетели были обучены правильной реакции на харассмент и поддерживали пострадавшую.
        </template>
        <template v-slot:question>
          Если женщина<br/> не пресекает домогательства, значит, ей это нравится.
        </template>
      </TestAnswer>
    </div>
    <div class="step-3">
      <testItem v-if="step == 3 && answer == false" @answer="setAnswer" :step="step" @close="closeTest">
        <template v-slot:title>
          Если я увижу домогательства<br/> и вмешаюсь, будет только хуже.
        </template>
      </testItem>
      <TestAnswer v-if="answer == true && step == 3 && answerText == 1" :step="step" @close="closeTest" @next="goToStep(4)">
        <template v-slot:title>
          Нет, вы не правы
        </template>
        <template v-slot:text>
          Огласка происходящего и помощь окружающих пострадавшей испугают агрессора. Он скорее прекратит домогаться, если увидит общественное осуждение и внимание к своему поведению. Даже если вы просто подойдете к жертве и будете стоять рядом, это больше поможет ей, чем полное невмешательство.
        </template>
        <template v-slot:question>
          Если я увижу домогательства<br/> и вмешаюсь, будет только хуже.
        </template>
      </TestAnswer>
      <TestAnswer v-if="answer == true && step == 3 && answerText == 0" :step="step" @close="closeTest" @next="goToStep(4)">
        <template v-slot:title>
          все Верно
        </template>
        <template v-slot:text>
          Огласка происходящего и помощь окружающих пострадавшей испугают агрессора. Он скорее прекратит домогаться, если увидит общественное осуждение и внимание к своему поведению. Даже если вы просто подойдете к жертве и будете стоять рядом, это больше поможет ей, чем полное невмешательство.
        </template>
        <template v-slot:question>
          Если я увижу домогательства<br/> и вмешаюсь, будет только хуже.
        </template>
      </TestAnswer>
    </div>
    <div class="step-4">
      <testItem v-if="step == 4 && answer == false" @answer="setAnswer" :step="step" @close="closeTest">
        <template v-slot:title>
          Тот, кто домогается, просто хочет сделать комплимент или пофлиртовать.
        </template>
      </testItem>
      <TestAnswer v-if="answer == true && step == 4 && answerText == 1" :step="step" @close="closeTest" @next="goToStep(5)">
        <template v-slot:title>
          Нет, это миф
        </template>
        <template v-slot:text>
          Флирт — это вид ухаживания, в котором партнеры в игровой форме показывают готовность<br/> к сексуальным отношениям. Флирт не может<br/> быть односторонним: если собеседник дал понять, что не заинтересован в более близком общении, дальнейшие действия — это<br/> не показатель настойчивости и серьезных намерений, а нарушение границ другого человека. <span class="just-desktop">В случае с комплиментами важно иметь в виду уместность, контекст и форму донесения комментария, а также то, что не всем людям нравится получать комплименты их внешности.</span>
        </template>
        <template v-slot:question>
          Тот, кто домогается, просто хочет сделать комплимент или пофлиртовать.
        </template>
      </TestAnswer>
      <TestAnswer v-if="answer == true && step == 4 && answerText == 0" :step="step" @close="closeTest" @next="goToStep(5)">
        <template v-slot:title>
          Все правильно
        </template>
        <template v-slot:text>
          Флирт — это вид ухаживания, в котором партнеры в игровой форме показывают готовность<br/> к сексуальным отношениям. Флирт не может<br/> быть односторонним: если собеседник дал понять, что не заинтересован в более близком общении, дальнейшие действия — это<br/> не показатель настойчивости и серьезных намерений, а нарушение границ другого человека. <span class="just-desktop">В случае с комплиментами важно иметь в виду уместность, контекст и форму донесения комментария, а также то, что не всем людям нравится получать комплименты их внешности.</span>
        </template>
        <template v-slot:question>
          Тот, кто домогается, просто хочет сделать комплимент или пофлиртовать.
        </template>
      </TestAnswer>
    </div>
    <div class="step-5">
      <testItem v-if="step == 5 && answer == false" @answer="setAnswer" :step="step" @close="closeTest">
        <template v-slot:title>
          Ответственность за домогательства лежит на агрессоре.
        </template>
      </testItem>
      <TestAnswer v-if="answer == true && step == 5 && answerText == 1" :step="step" @close="closeTest" @next="goToStep(6)">
        <template v-slot:title>
          Все верно
        </template>
        <template v-slot:text>
          В домогательствах виноват только агрессор, поскольку он принимает решение о нарушении чужих границ и не уважает чувства и желания объекта своего внимания. Перекладывание ответственности на пострадавшую называется виктимблеймингом — это попытка нашего мозга справиться с подорванной верой<br/> в справедливый мир и вернуть контроль над происходящим с нами. Любой человек имеет право на безопасность в общественном месте вне зависимости от времени суток, количества людей вокруг, особенностей его внешности<br/> и поведения.
        </template>
        <template v-slot:question>
          Ответственность за домогательства лежит на агрессоре.
        </template>
      </TestAnswer>
      <TestAnswer v-if="answer == true && step == 5 && answerText == 0" :step="step" @close="closeTest" @next="goToStep(6)">
        <template v-slot:title>
          Это не так
        </template>
        <template v-slot:text>
          В домогательствах виноват только агрессор, поскольку он принимает решение о нарушении чужих границ и не уважает чувства и желания объекта своего внимания. Перекладывание ответственности на пострадавшую называется виктимблеймингом — это попытка нашего мозга справиться с подорванной верой<br/> в справедливый мир и вернуть контроль над происходящим с нами. Любой человек имеет право на безопасность в общественном месте вне зависимости от времени суток, количества людей вокруг, особенностей его внешности<br/> и поведения.
        </template>
        <template v-slot:question>
          Ответственность за домогательства лежит на агрессоре.
        </template>
      </TestAnswer>
    </div>
    <TestFinish v-if="step == 6" @close="closeTest" />
    <div id="insta">
      <block8 />
    </div>
    <!-- <div id="webinars">
      <block9 />
    </div> -->

    <div class="test-link test-link--mobile">
      <div class="container">
        <div class="test-link__title">Читать статьи по теме.</div>
        <div class="test-link__title test-link__title--mobile">Читать статьи<br> по теме.</div>
        <div class="test-link__button">
          <a href="https://www.psychologies.ru/themes/westandup/" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_read_more'});"><span>Подробнее</span></a>
        </div>
      </div>
    </div>
    <FooterBlock />
  </div>
</template>

<script>
import HeaderBlock from './components/headerBlock.vue'
import HeaderBlockFixed from './components/headerBlockFixed.vue'
import MainBlock from './components/mainBlock.vue'
import block1 from './components/block-1.vue'
import block2 from './components/block-2.vue'
import block3 from './components/block-3.vue'
import block4 from './components/block-4.vue'
import block5 from './components/block-5.vue'
import block6 from './components/block-6.vue'
import block7 from './components/block-7.vue'
import block8 from './components/block-8.vue'
//import block9 from './components/block-9.vue'
import FooterBlock from './components/footerBlock.vue'
import testItem from './components/test-1.vue'
import TestAnswer from './components/test-answer.vue'
import TestFinish from './components/test-finish.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  name: 'App',
  components: {
    HeaderBlock,
    HeaderBlockFixed,
    MainBlock,
    block1,
    block2,
    block3,
    block4,
    block5,
    block6,
    block7,
    block8,
    //block9,
    FooterBlock,
    testItem,
    TestAnswer,
    TestFinish,
  },
  data() {
    return {
      sticky: false,
      step: 0,
      answer: 0,
      answerText: 0,
      scrolls: {
        scroll_25: false,
        scroll_50: false,
        scroll_75: false,
        scroll_90: false,
        scroll_100: false,
      },
    }
  },
  mounted () {
    this.$gtag.pageview("/");
    this.$nextTick(() => {
      AOS.init({
        duration: 700,
        offset: 100
      })
      window.addEventListener('scroll', this.handleScroll);
    })
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 100) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
      var h = document.documentElement, 
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var precent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
      if (precent > 25 && !this.scrolls.scroll_25) {
        this.$gtag.pageview({page_path: '/what'})
        this.scrolls.scroll_25 = true;
      }
      if (precent > 50 && !this.scrolls.scroll_50) {
        this.$gtag.pageview({page_path: '/why'})
        this.scrolls.scroll_50 = true;
      }
      if (precent > 75 && !this.scrolls.scroll_75) {
        this.$gtag.pageview({page_path: '/what-do'})
        this.scrolls.scroll_75 = true;
      }
      if (precent > 90 && !this.scrolls.scroll_90) {
        this.$gtag.pageview({page_path: '/read-more'})
        this.scrolls.scroll_90 = true;
      }
    },
    closeTest() {
      this.step = 0;
    },
    setAnswer(answer) {
      this.answer = true;
      this.answerText = answer;
    },
    goToStep(step) {
      this.step = step;
      this.answer = false;
      this.answerText = '';
    }
  },
  watch: {
    step(value) {
      if (value == 1) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_start'});
      }
      if (value == 2) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_1'});
      }
      if (value == 3) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_2'});
      }
      if (value == 4) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_3'});
      }
      if (value == 5) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_4'});
      }
      if (value == 6) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_5'});
        this.$gtag.event('click', {event_category: 'link','event_label': 'test_result'});
      }
    }
  }
}
</script>

<style>
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}
@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Book.eot');
    src: local('Gotham Book'), local('Gotham-Book'),
        url('./assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Book.woff') format('woff'),
        url('./assets/fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Medium.eot');
    src: local('Gotham Medium'), local('Gotham-Medium'),
        url('./assets/fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Gotham-Medium.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Medium.woff') format('woff'),
        url('./assets/fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./assets/fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
    url('./assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/GothamPro.woff') format('woff'),
    url('./assets/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./assets/fonts/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
    url('./assets/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/GothamPro-Medium.woff') format('woff'),
    url('./assets/fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
</style>

<style lang="sass">
  body
    font-family: 'Gotham'
  .container 
    width: 1260px
    margin: auto
  #app
    position: relative
    overflow: hidden
    width: 100%
  .title
    font-size: 50px
    line-height: 55px
    color: #FE553A
    font-weight: 500
    letter-spacing: -0.2px
  .text
    font-size: 15px
    line-height: 22px
    letter-spacing: -0.1px
    color: #000
  @media screen and (max-width: 1000px)
    *
      box-sizing: border-box
    .container
      width: 100%
      padding-left: vw(15px)
      padding-right: vw(15px)
    .title
      font-size: vw(30px)
      line-height: vw(33px)
      letter-spacing: normal
      br
        display: none
    .text
      font-size: vw(15px)
      line-height: vw(22px)
      br
        display: none
</style>