import { render, staticRenderFns } from "./test-finish.vue?vue&type=template&id=85966caa&scoped=true&"
import script from "./test-finish.vue?vue&type=script&lang=js&"
export * from "./test-finish.vue?vue&type=script&lang=js&"
import style0 from "./test-finish.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./test-finish.vue?vue&type=style&index=1&id=85966caa&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85966caa",
  null
  
)

export default component.exports