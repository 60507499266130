<template>
  <div class="test">
    <div class="container">
      <div class="test__content">
        <div class="test__info">
          <div class="test__close" @click="$emit('close')"></div>
          <div class="test__title"><slot name="title"></slot></div>
          <div class="test__pagination">{{step}}/5</div>
        </div>
        <div class="test__buttons">
          <div class="test__button">
            <a href="javascript:void(0)" @click="$emit('answer', 1);">Правда</a>
          </div>
          <div class="test__button">
            <a href="javascript:void(0)" @click="$emit('answer', 0);">Миф</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block7',
  props: {
    step: Number,
  }
}
</script>


<style lang="sass">
  .test
    background-color: #FE553A
    padding: 65px 0
    &__content
      width: 990px
      height: 515px
      background-color: #fff
      border-radius: 20px
      margin: auto
      display: flex
    &__title
      font-size: 32px
      font-weight: 500
      line-height: 38px
      color: #FE553A
      position: relative
      z-index: 10
    &__info
      background-color: #FAF3EE
      position: relative
      border-radius: 20px 0 0 20px
      padding-left: 60px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      width: 327px
      min-width: 327px
      position: relative
      &:after
        content: ''
        width: 150px
        height: 100%
        background-color: #FAF3EE
        display: block
        position: absolute
        top: 0
        right: -88px
        transform: skew(-10deg)
    &__pagination
      font-size: 18px
      font-weight: 500
      color: #000
      position: absolute
      bottom: 39px
    &__buttons
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      width: 663px
      margin-left: 78px
    &__button
      margin-top: 5px
      margin-bottom: 5px
      a
        font-size: 16px
        font-family: 'Gotham Pro'
        color: #000
        border: 1.5px solid #FE553A
        height: 60px
        line-height: 60px
        border-radius: 50px
        display: inline-block
        width: 302px
        text-decoration: none
        text-align: center
        transition: all 150ms
        &:hover
          color: #fff
          background: #FE553A
    &__close
      display: none

  @media screen and (max-width: 1000px)
    .test__content
      width: 100%
      height: vw(595px)
      flex-direction: column
      border-radius: vw(15px)
    .test__info
      width: 100%
      min-width: 100%
      border-radius: vw(15px) vw(15px) 0 0
      padding-left: 0
      justify-content: center
      padding: vw(71px) vw(19px) 0 vw(19px)
      height: vw(366px)
    .test__buttons
      width: 100%
      margin-left: 0
      margin-top: vw(38px)
    .test__button a
      width: vw(280px)
      line-height: vw(57px)
      font-size: vw(16px)
      height: vw(60px)
      border-radius: vw(50px)
    .test__button
      margin-top: vw(8px)
      margin-bottom: vw(8px)
    .test__info:after
      width: 0
      height: 0
    .test__title
      font-size: vw(26px)
      line-height: vw(30px)
      text-align: center
      br
        display: none
    .test__pagination
      bottom: 0
      top: vw(59px)
      right: 0
      left: 0
      text-align: center
      font-size: vw(18px)
    .test
      padding: vw(20px) 0
    .test__close
      background-image: url(../assets/img/close.svg)
      width: vw(16px)
      height: vw(16px)
      background-size: contain
      background-repeat: no-repeat
      display: block
      margin-left: auto
      cursor: pointer
      position: absolute
      top: vw(20px)
      right: vw(19px)
      z-index: 100
</style>
