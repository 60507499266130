<template>
  <div class="test-link" id="test">
    <div class="container">
      <div class="test-link__title">Проверьте свои знания <br>о домогательствах.</div>
      <div class="test-link__title test-link__title--mobile">Проверьте <br>свои знания о домогательствах.</div>
      <div class="test-link__button">
        <a href="javascript:void(0)" @click="$emit('nextStep')"><span>Пройти тест</span></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block7',
}
</script>


<style lang="sass">
  .test-link
    background-color: #FE553A
    padding-top: 152px
    padding-bottom: 151px
    &--mobile
      display: block
      background-color: #FAF3EE
      .test-link__title
        color: #FE553A
      .test-link__button
        a
          border: 1.5px solid #FE553A
    &__title
      font-size: 90px
      font-weight: 500
      line-height: 103px
      color: #fff
      letter-spacing: -0.4px
      text-align: center
      &--mobile
        display: none
    &__button
      text-align: center
      margin-top: 65px
      a
        font-size: 16px
        font-family: 'Gotham Pro'
        font-weight: 500
        color: rgba(0, 0, 0, 0.8)
        background-color: #fff
        height: 70px
        line-height: 70px
        border-radius: 50px
        display: inline-flex
        align-items: center
        text-decoration: none
        background-size: 43px
        background-repeat: no-repeat
        background-image: url(../assets/img/arrow.svg)
        background-position: 85% 50%
        transition: all 150ms
        padding-left: 39px
        padding-right: 158px
        transition: all 300ms
        span
          transition: all 300ms
        &:hover
          background-position: 93% 50%
          span
            transform: translateX(20px)

  @media screen and (max-width: 1000px)
    .test-link__title
      font-size: vw(30px)
      line-height: vw(33px)
      display: none
      letter-spacing: normal
      &--mobile
        display: block
    .test-link
      padding-top: vw(50px)
      padding-bottom: vw(50px)
    .test-link__button a
      height: vw(55px)
      line-height: vw(55px)
      padding-left: vw(30px)
      padding-right: vw(138px)
      background-position: 87% 50%
      font-size: vw(16px)
      border-radius: vw(50px)
      background-size: vw(43px)
    .test-link__button 
      margin-top: vw(30px)
</style>
