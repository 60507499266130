<template>
  <div class="insta">
    <div class="insta__bg"></div>
    <div class="container">
      <div class="insta__top">
        <div class="insta__title title">#WeStandUp</div>
        <div class="insta__text text">Пройдите обучающий тренинг L’Oreal Paris и поделитесь с другими информацией о том, как безопасно для себя и окружающих противостоять сексуальным домогательствам в общественных местах! Публикуйте свои истории в Instagram с хештегами #WeStandUp и #ДайОтпор и присоединяйтесь к международному движению STAND UP, чтобы как можно больше людей узнали об этой проблеме и умели правильно себя вести в таких ситуациях — ведь это касается каждого из нас.</div>
      </div>
        <div class="insta__help">Листайте, чтобы<br>прочитать все истории</div>
        <div class="insta__buttons buttons">
          <div class="button-prev" @click="swiper.slidePrev()"></div>
          <div class="button-next" @click="swiper.slideNext()"></div>
        </div>
      <div class="insta__list" v-if="posts">
        <div class="swiper-container swiper-container-inst">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="post in posts" :key="post.id">
              <div class="insta__item">
                <a :href="post.url" target="_blank">
                  <div class="insta__item-image">
                    <img :src="post.image" alt="">
                  </div>
                  <div class="insta__item-name">
                    <div class="insta__item-icon"></div>
                    <div class="insta__item-nickname">{{post.username}}</div>
                  </div>
                  <div class="insta__item-text">
                    {{post.text}}
                  </div>
                  <div class="insta__item-link">Читать далее</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
export default {
  name: 'block8',
  data() {
    return {
      posts: null,
      swiper: null
    }
  },
  async mounted() {
    await axios.get('https://loreal2021.hsmp.ru/posts/?page_size=99').then((data) => {
      this.posts = data.data.results;
    });
    this.$nextTick(() => {
      this.swiper = new Swiper('.swiper-container-inst', {
        slidesPerView: 1.5,
        loop: true,
        autoHeight: true,
        centeredSlides: true,
        breakpoints: {
          1000: {
            centeredSlides: false,
            slidesPerView: 3,
          }
        }
      });
    })
  }
}
</script>


<style lang="sass">
  .buttons
    display: flex
    justify-content: space-between
    width: 100%
    position: absolute
    padding-top: 130px
  .button
    &-prev
      width: 60px
      height: 60px
      background-image: url(../assets/img/slider-arrow.svg)
      transform: rotate(180deg)
      position: relative
      z-index: 100
      cursor: pointer
      background-size: contain
      transition: all 300ms
      &:hover
        transform: rotate(180deg) scale(1.05)
    &-next
      width: 60px
      height: 60px
      background-image: url(../assets/img/slider-arrow.svg)
      position: relative
      z-index: 100
      cursor: pointer
      background-size: contain
      transition: all 300ms
      &:hover
        transform: scale(1.05)
  .insta
    background-color: #fff
    padding-top: 174px
    min-height: 896px
    position: relative
    .container
      position: relative
    &__bg
      width: 100%
      height: 896px
      background-image: url(../assets/img/bg-element-2.svg)
      background-size: 215px
      background-repeat: no-repeat
      position: absolute
      z-index: 1
      background-position: 103.5% -90px
      top: 0
    &__top
      display: flex
      justify-content: space-between
      margin-bottom: 74px
    &__title
      font-size: 76px !important
      margin-top: 5px
      z-index: 2
    &__text
      width: 540px
      letter-spacing: normal !important
      z-index: 2
      &--mobile
        display: none
    &__list
      display: flex
      justify-content: center
      position: relative
      z-index: 100
      width: 1052px
      margin: auto
      padding-bottom: 119px
    &__item
      width: 300px
      margin-left: auto
      margin-right: auto
      z-index: 2
      a
        text-decoration: none
      img
        width: 100%
      &-name
        display: flex
        align-items: center
        margin-top: 22px
        margin-bottom: 16px
      &-icon
        background-image: url(../assets/img/insta.svg)
        width: 24px
        height: 24px
        background-size: contain
        background-repeat: no-repeat
        margin-right: 10px
      &-nickname
        font-size: 16px
        color: #000
        font-family: 'Gotham Pro'
        font-weight: 500
      &-text
        font-size: 14px
        line-height: 19px
        color: #000
        max-height: 210px
        position: relative
        overflow: hidden
        &:before
          content: ''
          bottom: 
          width: 100%
          height: 30px
          background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
          position: absolute
          bottom: 0
          left: 0
      &-link
        margin-top: 5px
        color: #FE553A
        font-size: 14px
        font-family: 'Gotham Pro'
        font-weight: 500
    &__help
      display: none

  @media screen and (max-width: 1000px)
    .insta__help
      display: block
      text-align: center
      line-height: 1.2
      margin-bottom: vw(40px)
      font-size: vw(14px)
    .insta__buttons
      padding-top: 0
      width: calc(100% - 8vw)
      box-sizing: border-box
      margin-top: vw(-80px)
      .button-prev
        width: vw(50px)
        height: vw(50px)
      .button-next
        width: vw(50px)
        height: vw(50px)
    .insta__top
      flex-direction: column
      margin-left: -2px
      margin-bottom: vw(39px)
    .insta__title
      font-size: vw(36px) !important
      margin-top: 0
      margin-bottom: vw(34px)
    .insta
      padding-top: vw(54px)
      height: auto
      padding-bottom: vw(50px)
      min-height: 0
    .insta__text
      width: 98%
    .insta__list
      width: 100vw
      margin-left: -4vw
      padding-bottom: 5vw
    .insta__item
      width: 90%
      min-width: 90%
      margin-right: vw(15px)
      margin-left: vw(15px)
    .insta__item-name
      margin-top: vw(12px)
      margin-bottom: vw(10px)
    .insta__item-nickname
      font-size: vw(14px)
    .insta__item-text
      font-size: vw(14px)
      line-height: vw(18px)
      height: vw(159px)
      position: relative
      overflow: hidden
      &:before
        content: ''
        bottom: 
        width: 100%
        height: vw(30px)
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
        position: absolute
        bottom: 0
        left: 0
      span
        color: #C4C4C4
    .insta__bg
      background-size: vw(93px)
      background-position: 99.5% 0
      height: vw(845px)
    .insta__item-icon
      width: vw(24px)
      height: vw(24px)
      margin-right: vw(10px)
</style>
