<template>
  <div class="feelings">
    <div class="container">
      <div class="feelings__top">
        <div class="feelings__title title" data-aos="fade-up">Что чувствуют <br>пострадавшие <br>от домогательств?</div>
        <div class="feelings__text feelings__text--1 text" data-aos="fade-up">Если обидчик делает то, что нравится исключительно ему, не задаваясь вопросом, что чувствует другой, <br>то жертва для него перестает быть личностью <br>со своими желаниями и выбором и превращается <br>в объект воздействия.</div>
      </div>
      <div class="feelings__image" data-aos="fade-up">
        <img src="../assets/img/feelings.jpg" alt="">
      </div>
      <div class="feelings__bottom">
        <div class="feelings__text feelings__text--2 text" data-aos="fade-up">
          <p>«Никому не нравится ощущать себя объектом чужого удовлетворения, особенно сексуального, даже если это реализовано в форме шуток. Люди не предметы, которые можно сравнивать или оценивать по каким-то характеристикам. У них есть своя собственная воля, которая может не совпадать с волей агрессора», — отмечает психолог.</p>
          <p>Как правило, пострадавшие от сексуальных домогательств чувствуют беспомощность и уязвимость, теряют ощущение безопасности — базовой потребности, которая дает нам ощущение, что жизни и здоровью ничего не угрожает.</p>
        </div>
        <div class="feelings__text feelings__text--3 text" data-aos="fade-up">
          <p>В ситуации домогательства никогда непонятно, что произойдет дальше: остановится ли человек на оскорбительной шутке или продолжит навязывать свою компанию, услышит ли, когда его попросят так не делать, или ситуация примет опасный оборот.</p>
          <p>Мужчинам бывает сложно понять, что чувствуют жертвы сексуальных домогательств. «Они переносят на женщину свое видение мира: для них вполне приемлемо грубо шутить друг <br>над другом. С их точки зрения непонятно, почему женщины воспринимают это как унижение, тем более если сами мужчины расценивают это как флирт или комплимент», — подчеркивает <br>Наира Парсаданян.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block5',
}
</script>


<style lang="sass">
  .feelings
    margin-top: 90px
    padding-bottom: 83px
    &__top
      display: flex
      justify-content: space-between
      padding-left: 0px
      padding-right: 92px
    &__text
      &--1
        width: 449px
        margin-top: 15px
      &--2
        width: 560px
      &--3
        width: 539px
      p
        margin-bottom: 12px
    &__image
      width: 100%
      margin-top: 72px
      margin-bottom: 53px
      img
        width: 100%
    &__bottom
      display: flex
      justify-content: space-between
      padding-left: 0px

  @media screen and (max-width: 1000px)
    .feelings
      display: none
</style>
