<template>
  <section class="vue-responsive-videobg main">
    <VideoModal video="ecejoD-oQF4" @close="video = false" v-if="video" />
    <div class="video-wrapper">
      <video autoplay playsinline loop  muted style="object-fit: cover;">
        <source :src="require('@/assets/main-video.mp4')" type="video/mp4">
      </video>
    </div>
    <div class="videobg-content">
      <div class="main__content">
        <div class="container">
          <div class="main__text" data-aos="fade-up">Дадим отпор домогательствам</div>
          <div class="main__button" data-aos="fade-up" @click="$gtag.event('click', {event_category: 'link','event_label': 'open_video'});">
            <a href="javascript:void(0)" @click="video = true">Посмотреть видео-манифест</a>
          </div>
        </div>
      </div>
      <div class="main__bottom" data-aos="fade-up">
        <div class="container">
          <div class="main__bottom-content">
            <p>от</p>
            <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank" class="main__logo-1" @click="$gtag.event('click', {event_category: 'link','event_label': 'main_logo'});"></a>
            <p>при участии</p>
            <a href="https://www.ihollaback.org/" class="main__logo-2" taret="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'main_logo_2'});"></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import VideoBackground from 'vue-responsive-video-background-player'
import VideoModal from '@/components/VideoModal'

Vue.component('video-background', VideoBackground);

export default {
  name: 'mainBlock',
  components: {
    VideoModal,
  },
  data() {
    return {
      video: false,
    }
  }
}
</script>


<style lang="sass">
  .main
    //background-image: url(../assets/img/main.jpg)
    //background-size: cover
    //background-repeat: no-repeat
    height: calc(100vh - 99px)
    margin-top: 99px
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-end
    &__content
      padding-left: 7px
    .video-wrapper
      //transform: scale(1.3)
    .videobg-content
      display: flex !important
      flex-direction: column !important
      justify-content: flex-end !important
    &__text
      font-size: 90px
      line-height: 103px
      font-weight: 500
      color: #FE553A
      letter-spacing: -0.5px
      margin-bottom: 22px
    &__button
      margin-left: -3px
      transform: translateY(1px)
      a
        font-size: 16px
        color: #fff
        font-family: 'Gotham Pro'
        text-decoration: none
        display: flex
        align-items: center
        width: 310px
        &:before
          content: ''
          width: 45px
          height: 45px
          background-size: contain
          background-repeat: no-repeat
          background-image: url(../assets/img/play.svg)
          display: block
          margin-right: 20px
          transition: all 300ms
        &:hover
          &:before
            background-image: url(../assets/img/play-active.svg)
    &__logo
      &-1
        background-image: url(../assets/img/logo-loreal.png)
        width: 181px
        height: 48px
        background-size: contain
        background-repeat: no-repeat
        margin-left: 31px
        margin-right: 58px
      &-2
        background-image: url(../assets/img/logo-hollaback.svg)
        width: 130px
        height: 54px
        background-size: contain
        background-repeat: no-repeat
        margin-left: 31px
        margin-bottom: 9px
    &__bottom 
      background: rgba(0, 0, 0, 0.4)
      padding-bottom: 36px
      padding-left: 11px
      padding-top: 34px
      margin-top: 45px
      &-content
        display: flex
        align-items: center
        margin-left: -3px
      p
        font-size: 16px
        color: #fff
        font-family: 'Gotham Pro'
        margin-bottom: 4px

  @media screen and (max-width: 1000px)
    .main
      margin-top: vw(96px)
      height: vw(538px)
    .main__text
      font-size: vw(36px)
      line-height: vw(41px)
      margin-bottom: vw(26px)
      letter-spacing: normal
    .main__bottom p
      font-size: vw(12px)
    .main__logo-1
      width: vw(83px)
      min-width: vw(83px)
      height: vw(22px)
      margin-bottom: vw(6px)
      margin-right: vw(20px)
      margin-left: vw(16px)
    .main__logo-2
      width: vw(67px)
      min-width: vw(67px)
      height: vw(28px)
      margin-bottom: vw(6px)
      margin-left: vw(17px)
    .main__bottom 
      padding-bottom: vw(24px)
      padding-left: vw(3px)
      padding-top: vw(31px)
      margin-top: vw(18px)
    .main__content
      padding-left: vw(0px)
    .main__button a:before
      width: vw(50px)
      min-width: vw(50px)
      height: vw(50px)
      margin-right: vw(15px)
    .main__button a
      width: vw(210px)
      line-height: vw(19px)
      font-size: vw(16px)
    .main__button
      margin-left: 0
</style>

<style scoped>
    .video-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        height: 100%;
        position: absolute;
        overflow: hidden;
        z-index: 0;
    }

    .fade{
        backface-visibility: hidden;
    }
    .fade-enter-active{
        transition: opacity 1s;
    }
    .fade-leave-active{
        transition: opacity 1s;
    }

    .fade-enter{
        opacity: 0;
    }
    .fade-leave-to{
        opacity: 0;
    }
    video {
        visibility: visible;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
    }
</style>
