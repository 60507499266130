<template>
  <div class="info">
    <div class="info__bg" data-aos="fade-left"></div>
    <div class="container">
      <div class="info__text" data-aos="fade-up">
        <p>Большинство женщин в течение жизни хоть <br>раз становились жертвами домогательств <br>в общественных местах. Часто пострадавшие боятся ответить агрессору, а свидетели не знают, нужно ли вмешиваться и как правильно вести себя в такой ситуации.</p>
        <p>Бренд L'Oreal Paris, который поддерживает идеи равенства, взаимоуважения и безопасности для женщин, запустил <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank">обучающую программу STAND UP</a>, чтобы мы могли научиться распознавать уличные домогательства и приходить на помощь тем, кто им подвергается.</p>
      </div>
      <div class="info__text info__text--mobile" data-aos="fade-up">
        <p>Большинство женщин в течение жизни хоть раз становились жертвами домогательств <br>в общественных местах.</p> 
        <p>Бренд L'Oreal Paris, который поддерживает идеи равенства, взаимоуважения и безопасности для женщин, запустил <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank">обучающую программу STAND UP</a>, чтобы мы могли научиться распознавать уличные домогательства и приходить на помощь тем, кто им подвергается.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block1',
}
</script>


<style lang="sass">
  .info
    height: 285px
    padding-top: 68px
    position: relative
    padding-left: 9px
    &__bg
      width: 100%
      height: 527px
      background-image: url(../assets/img/bg-element-1.svg)
      background-size: 243px
      background-repeat: no-repeat
      position: absolute
      z-index: 1
      background-position: 100% 0
      top: 0
    &__text
      font-size: 30px
      line-height: 39.3px
      font-weight: 500
      color: #FE553A
      letter-spacing: -0.05px
      width: 1037px
      position: relative
      z-index: 2
      margin-left: -4px
      a
        color: #FE553A
      @media screen and (min-width: 1000px)
        display: flex
        font-size: 24px
        line-height: 31px
        width: 100%
        p
          &:first-child
            width: 694px
            padding-right: 80px
            min-width: 694px
      &--mobile
        display: none

  @media screen and (max-width: 1000px)
    .info__text
      font-size: vw(18px)
      line-height: vw(25px)
      width: 100%
      letter-spacing: normal
      display: none
    .info__text--mobile
      display: block
      p
        margin-bottom: 12px
    .info
      padding-top: vw(41px)
      padding-left: vw(4px)
      height: auto
    .info__bg
      height: vw(362px)
      background-size: vw(260px)
      background-position: vw(256px) vw(-110px)
</style>
