<template>
  <div class="help">
    <div class="container">
      <div class="help__top" data-aos="fade-up">
        <div class="help__image">
          <img src="../assets/img/help.jpg" alt="">
        </div>
        <div class="help__head">
          <div class="help__title title">Что делать, если <br>вы стали свидетелем <br>домогательств?</div>
          <div class="help__text text">
            <p>«Самое важное и главное — нужно оценить риски от вашего вмешательства: насколько это безопасно для вас и для человека, подвергающегося нежелательному вниманию», — советует консультант по вопросам насилия.</p>
            <p>Исходя из обстоятельств, можно воспользоваться одним <br>из эффективных способов борьбы с домогательствами, разработанными L'Oreal Paris, — <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank">правил «5Д».</a> Их легко запомнить:</p>
          </div>
        </div>
      </div>
      <div class="help__list">
        <div class="help__item" data-aos="fade-up">
          <div class="help__item-head">
            <div class="help__icon">
              <img src="../assets/img/icon-1.svg" alt="">
            </div>
            <div class="help__item-title">Дезориентируй</div>
          </div>
          <div class="help__item-text text">Отвлеките обидчика. «Можно вмешаться в разговор, увести пострадавшую, например, сказав, что она — ваша старая знакомая или зачем-то вам срочно <br>нужна, не акцентируя внимания <br>на увиденном», — советует Наира Парсаданян.</div>
        </div>
        <div class="help__item" data-aos="fade-up">
          <div class="help__item-head">
            <div class="help__icon">
              <img src="../assets/img/icon-2.svg" alt="">
            </div>
            <div class="help__item-title">Делегируй</div>
          </div>
          <div class="help__item-text text">Попросите о помощи людей поблизости. Можно обратиться к человеку, наделенному властью в данной ситуации: водителю автобуса, охраннику, бармену или полицейскому. Чем больше людей обратят внимание на происходящее, тем менее комфортно будет обидчику продолжать домогательство.</div>
        </div>
        <div class="help__item" data-aos="fade-up">
          <div class="help__item-head">
            <div class="help__icon">
              <img src="../assets/img/icon-3.svg" alt="">
            </div>
            <div class="help__item-title">Документируй</div>
          </div>
          <div class="help__item-text text">Снимите происходящее на камеру или запишите на диктофон. Однако помните: ваши действия должны помогать человеку, поэтому предоставьте эти записи пострадавшей и не выкладывайте их никуда без ее согласия. </div>
        </div>
        <div class="help__item" data-aos="fade-up">
          <div class="help__item-head">
            <div class="help__icon">
              <img src="../assets/img/icon-4.svg" alt="">
            </div>
            <div class="help__item-title">Действуй</div>
          </div>
          <div class="help__item-text text">Используйте этот способ только в крайнем случае для предотвращения насилия. «Прямо обратитесь к агрессору и назовите вещи своими именами: то, что сейчас происходит — это преследование, унижение, оскорбление», — говорит психолог. Попросите его оставить <br>другого человека в покое или начните диалог с пострадавшей о том, что происходит.</div>
        </div>
        <div class="help__item" data-aos="fade-up">
          <div class="help__item-head">
            <div class="help__icon">
              <img src="../assets/img/icon-5.svg" alt="">
            </div>
            <div class="help__item-title">Демонстрируй поддержку</div>
          </div>
          <div class="help__item-text text">Поговорите с пострадавшей <br>о случившемся, однако не навязывайте <br>ей свою компанию. Покажите, что <br>не считаете поведение обидчика нормальным и не вините ее <br>в произошедшем.</div>
        </div>
      </div>
      <div class="help__list help__list--mobile">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="help__item" data-aos="fade-up">
                <div class="help__item-head">
                  <div class="help__icon">
                    <img src="../assets/img/icon-1.svg" alt="">
                  </div>
                  <div class="help__item-title">Дезориентируй</div>
                </div>
                <div class="help__item-text text">Отвлеките обидчика. Можно вмешаться в разговор, увести пострадавшую, например, сказав, что она — ваша старая знакомая или зачем-то вам срочно нужна, не акцентируя внимания на увиденном</div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="help__item" data-aos="fade-up">
                <div class="help__item-head">
                  <div class="help__icon">
                    <img src="../assets/img/icon-2.svg" alt="">
                  </div>
                  <div class="help__item-title">Делегируй</div>
                </div>
                <div class="help__item-text text">Попросите о помощи людей поблизости. Можно обратиться к человеку, наделенному властью в данной ситуации: водителю автобуса, охраннику, бармену или полицейскому. Чем больше людей обратят внимание на происходящее, тем менее комфортно будет обидчику продолжать домогательство.</div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="help__item" data-aos="fade-up">
                <div class="help__item-head">
                  <div class="help__icon">
                    <img src="../assets/img/icon-3.svg" alt="">
                  </div>
                  <div class="help__item-title">Документируй</div>
                </div>
                <div class="help__item-text text">Снимите происходящее на камеру или запишите на диктофон. Однако помните: ваши действия должны помогать человеку, поэтому предоставьте эти записи пострадавшей и не выкладывайте их никуда без ее согласия. </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="help__item" data-aos="fade-up">
                <div class="help__item-head">
                  <div class="help__icon">
                    <img src="../assets/img/icon-4.svg" alt="">
                  </div>
                  <div class="help__item-title">Действуй</div>
                </div>
                <div class="help__item-text text">Используйте этот способ только в крайнем случае для предотвращения насилия. «Прямо обратитесь к агрессору и назовите вещи своими именами: то, что сейчас происходит — это преследование, унижение, оскорбление», — говорит психолог. Попросите его оставить <br>другого человека в покое или начните диалог с пострадавшей о том, что происходит.</div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="help__item" data-aos="fade-up">
                <div class="help__item-head">
                  <div class="help__icon">
                    <img src="../assets/img/icon-5.svg" alt="">
                  </div>
                  <div class="help__item-title">Демонстрируй поддержку</div>
                </div>
                <div class="help__item-text text">Поговорите с пострадавшей <br>о случившемся, однако не навязывайте <br>ей свою компанию. Покажите, что <br>не считаете поведение обидчика нормальным и не вините ее <br>в произошедшем.</div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="help__text help__text--mobile text">
        <p>По мнению Наиры Парсаданян, также можно посоветовать пострадавшей обратиться за юридической помощью в профильные организации, работающие с такой темой. Возможно, ей понадобится и помощь психолога.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper/bundle';
  import 'swiper/swiper-bundle.css';

  export default {
    name: 'block6',

    data() {
      return {
        swiper: null,
      }
    },

    mounted() {
      this.swiper = new Swiper('.swiper-container', {
        loop: true,
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
        }
      });
    }
  }
</script>


<style lang="sass">
  .help
    background-color: #FAF3EE
    padding-top: 101px
    padding-bottom: 21px
    &__image
      width: 650px
      min-width: 650px
      margin-left: -50px
      img
        width: 100%
    &__top
      display: flex
      justify-content: space-between
    &__text
      width: 481px
      padding-left: 6px
      a
        color: #000
      p
        margin-bottom: 10px
      &--mobile
        display: none
    &__title
      margin-top: 14px
      margin-bottom: 61px
    &__list
      display: flex
      flex-wrap: wrap
      width: calc(100% + 130px)
      margin-left: -60px
      margin-top: 77px
      &--mobile
        display: none
    &__item
      width: 330px
      margin-right: 65px
      margin-left: 65px
      margin-bottom: 76px
      &-title
        font-size: 30px
        font-weight: 500
        color: #FE553A
        line-height: 39px
        margin-bottom: 17px
    &__icon
      width: 55px
      min-width: 55px
      height: 55px
      background-color: #fff
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 17px

  @media screen and (max-width: 1000px)
    .help__top
      flex-direction: column
    .help__image
      margin-left: 0
      width: 100%
      min-width: 100%
    .help__list
      margin-left: 0
      width: 100%
      margin-top: vw(22px)
      display: none
      &--mobile
        display: block
    .help
      padding-top: vw(50px)
      padding-bottom: vw(42px)
    .help__title
      margin-top: vw(27px)
      margin-bottom: vw(29px)
      br
        display: block !important
    .help__text
      width: 100%
      padding-left: 0
      letter-spacing: normal !important
      line-height: vw(20.1px) !important
    .help__text--mobile
      display: block
      margin-top: vw(47px)
    .help__text p
      margin-bottom: vw(8px)
    .help__item-head
      display: flex
      margin-bottom: vw(20px)
      align-items: center
    .help__item
      margin-left: 0
      margin-right: 0
      margin-bottom: 0
      width: 100%
    .help__item-title
      font-size: vw(22px)
      line-height: vw(24px)
      margin-bottom: 0
    .help__icon
      margin-right: vw(20px)
      margin-bottom: 0
    .help__item-text
      letter-spacing: normal !important
      line-height: vw(20.1px) !important
    .swiper-pagination-bullet
      border: 1px solid #FE553A
      background-color: #fff
      width: vw(10px)
      height: vw(10px)
      border-radius: 50%
      opacity: 1
    .swiper-container
      padding-bottom: vw(25px)
    .swiper-pagination-bullet-active
      background-color: #FE553A
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet
      margin: 0 vw(6px)
    .swiper-pagination-bullets
      bottom: 0 !important
</style>
