<template>
  <div class="why">
    <div class="container">
      <div class="why__content">
        <div class="why__left">
          <div class="why__image-1" data-aos="fade-up">
            <img src="../assets/img/why-1.jpg" alt="">
          </div>
          <div class="why__text text" data-aos="fade-up">
            <p class="first">В сексуальных домогательствах стоит винить и установки, которое общество транслирует мужчинам:</p>
            <h2>1. «Мальчики не плачут»</h2>
            <p>То есть не имеют право показывать слабость и уязвимость, <br>а значит быть чуткими и нежными.</p>
            <h2>2. «Что ты как девчонка!»</h2>
            <p>То есть мужчина должен избегать всех характеристик, которые свойственны женщинам, и в подобных посылах уже заложено отношение к последним как к людям второго сорта.</p>
            <h2>3. «Мужчина — глава семьи»</h2>
            <p>То есть только он имеет право устанавливать правила <br>в поло-ролевой коммуникации.</p>
            <p class="last">В результате получается замкнутый круг: к женщине плохо относятся — никто на это не реагирует — подобные ситуации повторяются.</p>
          </div>
        </div>
        <div class="why__right" data-aos="fade-up">
          <div class="why__image-1 why__image-1--mobile" data-aos="fade-up">
            <img src="../assets/img/why-1.jpg" alt="">
          </div>
          <div class="why__title title">Почему одни <br>люди домогаются <br>других?</div>
          <div class="why__text text">
            <p>По словам Наиры Парсаданян, сексуальные домогательства становятся возможными, когда один человек дает себе внутреннее разрешение на унижение другого. В таких случаях у агрессора отсутствуют достаточная чувствительность <br>и эмпатия, которая обычно формируется в процессе семейного воспитания и гендерной социализации.</p>
            <p class='just-desktop'>Например, если мальчик с детства видел пример неуважительного отношения к матери, к сестрам, одноклассницам, то он будет воспринимать такое поведение как норму.</p>
            <p class='just-desktop'>Другой важный фактор — формирование в пубертатном периоде сексуального интереса через просмотр порнографии, в рамках которой секс в большинстве случаев преподносится через насилие и объективацию женщин.</p>
          </div>
          <div class="why__image-2" data-aos="fade-up">
            <img src="../assets/img/why-2.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block4',
}
</script>


<style lang="sass">
  .why
    padding-top: 90px
    background-color: #FAF3EE
    padding-bottom: 85px
    &__content
      display: flex
      justify-content: space-between
    &__image
      img
      &-1
        width: 589px
        margin-top: 11px
        margin-left: -50px
        margin-bottom: 51px
        img
          width: 100%
        &--mobile
          display: none
      &-2
        width: 578px
        margin-right: -39px
        margin-top: 70px
        img
          width: 100%
    &__title
      margin-bottom: 68px
    &__text
      width: 500px
      h2
        font-size: 18px
        line-height: 26px
        font-weight: 500
        color: #FE553A
        text-transform: uppercase
        letter-spacing: 0px
        margin-bottom: 10px
    &__left
      .why__text
        padding-left: 0px
        p
          margin-bottom: 20px
          &.first
            margin-bottom: 33px
          &.last
            margin-top: 30px
    &__right
      .why__text
        p
          margin-bottom: 12px
          letter-spacing: 0px

  @media screen and (max-width: 1000px)
    .why__content
      flex-direction: column
    .why__image-1
      width: 100%
      margin-left: 0
      display: none
      margin-bottom: vw(27px)
    .why
      background-color: #fff
      padding-top: vw(39px)
      padding-bottom: vw(30px)
    .why__right
      order: 1
    .why__left
      order: 2
    .why__image-1--mobile
      display: block
    .why__text
      width: 100%
      line-height: vw(20.1px) !important
      letter-spacing: normal !important
      p
        &.just-desktop
          display: none
    .why__title
      margin-bottom: vw(30px)
    .why__image-2
      width: 100%
      margin-right: 0
      margin-top: vw(30px)
      margin-bottom: vw(18px)
    .why__left .why__text p.first
      margin-bottom: vw(20px)
    .why__text h2
      font-size: vw(16px)
      line-height: vw(21px)
      margin-bottom: vw(5px)
    .why__left .why__text p.last
      margin-top: vw(20px)
</style>
