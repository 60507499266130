<template>
  <div class="header">
    <div class="container">
      <div class="header__content">
        <div class="header__logo-1" @mouseover="hoverLogo = true" @mouseleave="logoMouseLeave" :class="{anim: hoverLogo, 'anim--reverse': reverseAnim}"  @click="$gtag.event('click', {event_category: 'link','event_label': 'header_logo'});"><a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count"></a></div>
        <div class="header__text">СПЕЦИАЛЬНЫЙ ПРОЕКТ</div>
        <a href="https://www.psychologies.ru/" target="_blank" class="header__logo-2"></a>
        <div class="header__cross">X</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerBlock',
  data() {
    return {
      hoverLogo: false,
      reverseAnim: false,
    }
  },
  methods: {
    logoMouseLeave() {
      this.reverseAnim = true;
      this.hoverLogo = false;
      setTimeout(() => {
        this.reverseAnim = false;
      }, 500)
    }
  }
}
</script>


<style lang="sass">
  .header
    position: fixed
    width: 100%
    left: 0
    top: 0
    z-index: 99999999
    background-color: #fff
    padding-top: 20px
    padding-bottom: 19px
    &__content
      display: flex
      align-items: center
      justify-content: space-between
    &__logo
      &-1
        width: 135px
        height: 60px
        background-image: url(../assets/img/logo-standup.svg)
        background-size: contain
        background-repeat: no-repeat
        display: flex
        align-items: center
        &:before
          content: ''
          display: block
          width: 135px
          height: 60px
          background-image: url(../assets/img/logo-u.svg)
          position: absolute
          z-index: 1
          transition: all 300ms
          transition-timing-function: ease-out
        &:after
          content: ''
          display: block
          width: 135px
          height: 60px
          background-image: url(../assets/img/logo-p.svg)
          position: absolute
          z-index: 1
          transition: all 300ms
          transition-timing-function: ease-out
        &.anim
          &:before
            animation: standup
            animation-fill-mode: forwards
            animation-duration: 500ms
          &:after
            animation: standup
            animation-fill-mode: forwards
            animation-duration: 700ms
          &--reverse
            &:before
              animation: standupReverse
            &:after
              animation: standupReverse
        a
          display: block
          width: 100%
          position: relative
          z-index: 2
          height: 100%
      &-2
        width: 197px
        height: 37px
        background-image: url(../assets/img/logo-psychologies.svg)
        background-size: contain
        background-repeat: no-repeat
    &__text
      font-size: 15px
      color: #000
      margin-left: 61px
    &__cross
      display: none

  @media screen and (max-width: 1000px)
    .header__content
      flex-wrap: wrap
      padding-right: vw(16px)
      padding-left: vw(16px)
    .header__text
      order: 1
      font-size: vw(12px)
      margin-left: 0
      width: 100%
      text-align: center
      margin-bottom: vw(12px)
    .header__logo-1
      order: 2
      width: vw(111px)
      height: vw(31px)
      background-image: url(../assets/img/logo-standup-2.svg)
      &:before
        display: none !important
      &:after
        display: none !important
    .header__logo-2
      order: 4
      width: vw(157px)
      height: vw(28px)
    .header
      padding-top: vw(23px)
      padding-bottom: vw(19px)
    .header__cross
      display: block
      order: 3
      font-size: 20px
      font-weight: 500
      color: rgba(0, 0, 0, 0.8)
  @keyframes standup
    0%
      transform: translateY(0px)
    50%
      transform: translateY(-10px)
    100%
      transform: translateY(-5px) scale(1, 0.88)
  @keyframes standupReverse
    0%
      transform: translateY(-5px) scale(1, 0.88)
    50%
      transform: translateY(-10px)
    100%
      transform: translateY(0px)
</style>
