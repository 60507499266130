<template>
  <div class="header header--sticky">
    <div class="container">
      <div class="header__content">
        <div class="header__button">
          <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'header_button'});">Пройти обучение</a>
        </div>
        <scrollactive  class="header__menu" :offset="99">
          <a class="header__menu-link scrollactive-item" href="#what">Что такое<br>домогательства</a>
          <a class="header__menu-link scrollactive-item" href="#why">Почему люди<br>нарушают границы</a>
          <a class="header__menu-link scrollactive-item" href="#do">Что делать, если<br>вы свидетель</a>
        </scrollactive>
        <div class="header__logo-1" @mouseover="hoverLogo = true" @mouseleave="logoMouseLeave" :class="{anim: hoverLogo, 'anim--reverse': reverseAnim}" @click="$gtag.event('click', {event_category: 'link','event_label': 'header_logo'});"><a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count"></a></div>
        <div class="header__burger" @click="openMenu = !openMenu" v-bind:class="{active: openMenu}">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="burger" v-bind:class="{active: openMenu}">
        <div class="container">
          <div class="burger__menu">
            <div class="burger__menu-item">
              <a v-scroll-to="'#what'" @click="openMenu = false">Что такое домогательства</a>
            </div>
            <div class="burger__menu-item">
              <a v-scroll-to="'#why'" @click="openMenu = false">Почему люди нарушают границы</a>
            </div>
            <div class="burger__menu-item">
              <a v-scroll-to="'#do'" @click="openMenu = false">Что делать, если вы свидетель</a>
            </div>
            <div class="burger__menu-item">
              <a v-scroll-to="'#test'" @click="openMenu = false">Пройти тест</a>
            </div>
          </div>
          <div class="social">
            <div class="social__title">Поделиться проектом:</div>
            <div class="social__content">
              <div class="social__item">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://westandup.psychologies.ru/&t=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
                  class="social__icon"
                   @click="$gtag.event('click', {event_category: 'link','event_label': 'share_facebook'});"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;">
                  <img src="../assets/img/icon/fb.svg" alt="">
                </a>
              </div>
              <div class="social__item">
                <a
                  href="https://vk.com/share.php?url=https://westandup.psychologies.ru/&title=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
                   @click="$gtag.event('click', {event_category: 'link','event_label': 'share_vk'});"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  class="social__icon">
                  <img src="../assets/img/icon/vk.svg" alt="">
                </a>
              </div>
              <div class="social__item">
                <a
                  href="https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.title=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор&st.shareUrl=https://westandup.psychologies.ru/"
                   @click="$gtag.event('click', {event_category: 'link','event_label': 'share_ok'});"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  class="social__icon">
                  <img src="../assets/img/icon/ok.svg" alt="">
                </a>
              </div>
              <div class="social__item">
                <a
                  href="https://twitter.com/intent/tweet?text=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор&url=https://westandup.psychologies.ru/"
                   @click="$gtag.event('click', {event_category: 'link','event_label': 'share_twitter'});"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  class="social__icon">
                  <img src="../assets/img/icon/twitter.svg" alt="">
                </a>
              </div>
              <div class="social__item">
                <a 
                  href="https://t.me/share/url?url=https://westandup.psychologies.ru/&text=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
                   @click="$gtag.event('click', {event_category: 'link','event_label': 'share_telegram'});"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  class="social__icon"
                  >
                  <img src="../assets/img/icon/telegram.svg" alt="">
                </a>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);
export default {
  name: 'headerBlockFixed',

  data() {
    return {
      openMenu: false,
      hoverLogo: false,
      reverseAnim: false,
    }
  },
  methods: {
    logoMouseLeave() {
      this.reverseAnim = true;
      this.hoverLogo = false;
      setTimeout(() => {
        this.reverseAnim = false;
      }, 500)
    }
  }
}
</script>


<style lang="sass">
  .header
    &--sticky
      z-index: 999999
    &__menu
      display: flex
      &-link
        margin-left: 25px
        margin-right: 25px
        color: #000
        text-decoration: none
        font-size: 13px
        display: block
        line-height: 16px
        text-align: center
        position: relative
        &:after
          bottom: -8px
          position: absolute
          content: ''
          display: block
          height: 3px
          background-color: #FE553A
          left: 0
          right: 0
          width: 0px
          margin: auto
          transition: all 300ms
        &:hover
          &:after
            width: 65px
        &.is-active
          color: #FE553A
          &:after
            width: 65px
    &__button
      a
        display: block
        width: 220px
        height: 60px
        line-height: 60px
        font-size: 14px
        color: #fff
        background-color: #FE553A
        border: 1.2px solid #FE553A
        text-align: center
        text-decoration: none
        border-radius: 61px
        transition: all 150ms
        &:hover
          background-color: #fff
          color: #FE553A
    &__burger
      display: none
  .burger
    display: none

  @media screen and (max-width: 1000px)
    .header__menu
      display: none
    .header__button a
      width: vw(160px)
      height: vw(40px)
      line-height: vw(40px)
      font-weight: 500
      font-size: vw(12px)
    .header__button
      order: 2
    .header__burger
      display: block
      width: vw(20px)
      order: 3
      span
        width: 100%
        height: 2px
        background-color: #000
        border-radius: 100px
        display: block
        margin-bottom: vw(4px)
        transition: all 300ms
        &:last-child
          margin-bottom: 0
      &.active
        span
          &:nth-child(1)
          transform: rotate(45deg)
          &:nth-child(2)
            width: 0
          &:nth-child(3)
            transform: rotate(-45deg)
            margin-top: -12px
    .burger
      display: block
      position: fixed
      width: 100vw
      height: 100vh
      background-color: #FE553A
      top: -200vh
      left: 0
      //transition: all 300ms
      &.active
        top: vw(70px)
      &__menu
        padding-top: vw(31px)
        padding-left: vw(68px)
        padding-right: vw(68px)
        &-item
          text-align: center
          margin-bottom: vw(18px)
          &:last-child
            &:after
              width: 0
          &:after
            content: ''
            width: vw(100px)
            height: 1px
            background-color: #fff
            display: block
            margin: auto
            margin-top: vw(16px)
          a
            font-size: vw(20px)
            line-height: vw(25px)
            text-align: center
            color: #fff
            text-decoration: none
            font-weight: 500
            letter-spacing: 0.2px
      .social
        position: absolute
        left: 0
        right: 0
        margin: auto
        bottom: vw(90px)
      .social__title
        font-size: vw(16px)
        letter-spacing: 0.2px
</style>

<style lang="sass" scoped>
  @function vw($px-vw, $base-vw: 375px)
    @return ($px-vw * 100vw) / $base-vw
  @media screen and (max-width: 1000px)
    .header__logo-1
      order: 1
      width: vw(73px)
      height: vw(20px)
    .header
      padding-top: vw(15px)
      padding-bottom: vw(15px)
    .header__content
      padding-left: vw(5px)
      padding-right: 0
</style>
