<template>
	<div class="modal">
		<div class="modal__bg" @click="$emit('close')"></div>
		<div class="modal__video">
			<div class="modal__close" @click="$emit('close')"></div>
			<youtube :video-id="video"  :player-vars="playerVars" ref="youtube"></youtube>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
 
Vue.use(VueYoutube)
export default {
	name: 'VideoModal',
	props: ['video'],
	data() {
		return {
      playerVars: {
        autoplay: 1
      }
		}
	}
}
</script>

<style lang="sass">
	.modal
		position: fixed
		width: 100vw
		height: 100vh
		top: 0
		left: 0
		z-index: 9999999999999999
		display: flex
		align-items: center
		justify-content: center
		&__close
			display: block
			width: 40px
			height: 40px
			background-image: url(../assets/img/close-white.svg)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			margin-top: 0vw
			margin-left: 79vw
			cursor: pointer
			border: 2px solid #FE553A
			border-radius: 50%
			background-color: #FE553A
			background-size: 20px
			transition: all 300ms
			background-position: 50% 50%
			&:hover
				transform: rotate(180deg)
			@media screen and (max-width: 960px)
				margin-left: 320px
				width: 30px
				height: 30px
				margin-top: -40px
				background-size: 15px
		&__bg
			width: 100%
			position: fixed
			top: 0
			left: 0
			height: 100%
			cursor: pointer
			background-color: rgba(0,0,0,0.5)
		&__video
			position: relative
			z-index: 100000
			width: 77vw
			background-color: #333
			height: 44vw
			@media screen and (max-width: 960px)
				width: 90vmin
				height: 50vw
			iframe
				width: 100%
				height: 100%

</style>