<template>
  <div class="footer">
    <div class="container">
      <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" class="footer__logo-1" target="_blank"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'footer_logo-1'});"></a>
      <div class="footer__text">Мировой лидер по производству косметики, компания L’Oréal имеет свои представительства <br>в 130 странах, на пяти континентах. Группа специализируется на выпуске инновационных косметических средств для женщин и мужчин со всего мира, соответствующих индивидуальным особенностям людей.</div>
      <a href="https://www.ihollaback.org/" target="_blank" class="footer__logo-2"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'footer_logo-2'});"></a>
      <div class="footer__text">Hollaback! – это всемирное общественное движение, направленное на борьбу <br>с домогательствами. Наше сотрудничество нацелено на обеспечение равноправия <br>и взаимоуважения в общественных местах.</div>
      <div class="social">
        <div class="social__title">Поделиться проектом:</div>
        <div class="social__content">
          <div class="social__item">
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://westandup.psychologies.ru/&t=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
              class="social__icon"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'share_facebook'});"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;">
              <img src="../assets/img/icon/fb.svg" alt="">
            </a>
          </div>
          <div class="social__item">
            <a
              href="https://vk.com/share.php?url=https://westandup.psychologies.ru/&title=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'share_vk'});"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
              class="social__icon">
              <img src="../assets/img/icon/vk.svg" alt="">
            </a>
          </div>
          <div class="social__item">
            <a
              href="https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.title=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор&st.shareUrl=https://westandup.psychologies.ru/"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'share_ok'});"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
              class="social__icon">
              <img src="../assets/img/icon/ok.svg" alt="">
            </a>
          </div>
          <div class="social__item">
            <a
              href="https://twitter.com/intent/tweet?text=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор&url=https://westandup.psychologies.ru/"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'share_twitter'});"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
              class="social__icon">
              <img src="../assets/img/icon/twitter.svg" alt="">
            </a>
          </div>
          <div class="social__item">
            <a 
              href="https://t.me/share/url?url=https://westandup.psychologies.ru/&text=L'Oreal Paris и ELLE делятся рекомендациями, как распознавать уличные домогательства и дать отпор"
              @click="$gtag.event('click', {event_category: 'link','event_label': 'share_telegram'});"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
              class="social__icon"
              >
              <img src="../assets/img/icon/telegram.svg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="footer__copy">© 2021,  HSM Creative Studio</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerBlock',
}
</script>


<style lang="sass">
  .footer
    background-color: #000
    padding-top: 70px
    padding-bottom: 31px
    &__text
      font-size: 13px
      line-height: 18px
      color: #fff
      text-align: center
      width: 650px
      margin: auto
      letter-spacing: -0.1px
    &__logo
      &-1
        background-image: url(../assets/img/logo-loreal.png)
        width: 151px
        height: 40px
        background-size: contain
        background-repeat: no-repeat
        display: block
        margin: auto
        margin-bottom: 15px
      &-2
        background-image: url(../assets/img/logo-hollaback.svg)
        width: 111px
        height: 46px
        background-size: contain
        background-repeat: no-repeat
        display: block
        margin: auto
        margin-top: 24px
        margin-bottom: 11px
    &__copy
      font-size: 16px
      font-family: 'Gotham Pro'
      color: #fff
      text-align: center
      margin-top: 24px
      letter-spacing: 0.3px
  .social
    margin-top: 53px
    &__content
      display: flex
      justify-content: center
    &__icon
      width: 42px
      height: 42px
      display: inline-flex
      align-items: center
      justify-content: center
      position: relative
      &:before
        content: ''
        border-radius: 50%
        border: 1px solid #fff
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        transition: all 300ms
      &:hover
        &:before
          transform: scale(1.2)
    &__item
      margin-right: 9px
      margin-left: 9px
    &__title
      display: none

  @media screen and (max-width: 1000px)
    .footer__logo-1
      width: vw(114px)
      margin-bottom: vw(16px)
      height: vw(30px)
    .footer__logo-2
      width: vw(97px)
      height: vw(40px)
      margin-bottom: vw(16px)
      margin-top: vw(29px)
    .footer
      padding-top: vw(55px)
      padding-bottom: vw(31px)
    .footer__text
      width: 100%
      letter-spacing: normal
      font-size: vw(13px)
      line-height: vw(18px)
      br
        display: none
    .social__title
      display: block
      font-size: vw(15px)
      color: #fff
      text-align: center
      margin-bottom: vw(16px)
    .social
      margin-top: vw(62px)
    .social__icon
      width: vw(40px)
      height: vw(40px)
    .social__item
      margin-left: vw(8px)
      margin-right: vw(8px)
    .footer__copy
      margin-top: vw(41px)
      font-size: vw(16px)
</style>
