<template>
  <div class="test">
    <div class="container">
      <div class="test__content test__content--finish">
        <div class="test__finish">
          <div class="test__close" @click="$emit('close')"></div>
          <div class="test__finish-title"><span>Сексуальные домогательства <br>в общественных местах</span> — серьезная <br>проблема, с которой сталкиваются <br>многие женщины.</div>
          <div class="test__finish-text"> Нехватка знаний, отсутствие инструментов защиты и непонимание чувств пострадавших развязывают руки агрессорам и делают мир небезопасным для всех. Пройдите тренинг STAND UP, разработанный брендом L'Oreal Paris совместно с общественной организацией Hollaback, чтобы научиться давать отпор сексуальным домогательствам — как в роли жертв агрессора, так и свидетеля.</div>
          <div class="test__finish-button">
            <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5740040386&iu=/81006599/hmiru-elle/count" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_after_test'});"><span>Пройти обучение</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block7',
}
</script>


<style lang="sass">
  .test
    &__content
      &--finish
        background-color: #FAF3EE
    &__finish
      padding-top: 48px
      &-title
        font-size: 30px
        line-height: 39px
        color: #181818
        text-align: center
        font-weight: 500
        margin-bottom: 24px
        span
          color: #FE553A
      &-text
        font-size: 15px
        line-height: 22px
        color: #181818
        text-align: center
        padding-left: 200px
        padding-right: 200px
      &-button
        text-align: center
        margin-top: 35px
        a
          font-size: 16px
          font-weight: 500
          color: #fff
          background-image: url(../assets/img/arrow-white.svg)
          background-size: 43px
          background-repeat: no-repeat
          background-position: 86% 50%
          display: inline-flex
          align-items: center
          height: 60px
          line-height: 60px
          background-color: #FE553A
          border-radius: 50px
          text-decoration: none
          padding-left: 39px
          padding-right: 133px
          transition: all 300ms
          span
            transition: all 300ms
          &:hover
            background-position: 93% 50%
            span
              transform: translateX(20px)

  @media screen and (max-width: 1000px)
    .test__finish-title
      font-size: vw(22px)
      line-height: vw(29.1px)
      margin-bottom: vw(20px)
      br
        display: none
    .test__finish-text
      padding: 0
      font-size: vw(14px)
      line-height: vw(18px)
      padding-left: vw(5px)
      padding-right: vw(5px)
    .test__finish
      position: relative
      padding: 0 vw(28px)
      padding-top: vw(50px)
      height: 100%
    .test__finish-button a
      width: vw(287px)
      padding-right: 0
      padding-left: vw(31px)
      background-position: 88% 50%
      font-size: vw(16px)
      line-height: vw(60px)
      height: vw(60px)
      background-size: vw(43px)
      border-radius: vw(50px)
    .test__finish-button
      margin-top: 0
      position: absolute
      bottom: vw(33px)
</style>

<style lang="sass" scoped>
  @function vw($px-vw, $base-vw: 375px)
    @return ($px-vw * 100vw) / $base-vw
  @media screen and (max-width: 1000px)
    .test__close
      top: vw(36px)
      right: vw(25px)
    .test__content
      background-color: #FAF3EE
</style>