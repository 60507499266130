import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueWaypoint from 'vue-waypoint'
import VueGtag from "vue-gtag"
Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-188164693-5" }
  /*config: { id: "UA-00000000-0" }*/
});


Vue.use(VueWaypoint)

 
Vue.use(VueScrollTo, {
	offset: -100
})

new Vue({
  render: h => h(App),
}).$mount('#app')
