<template>
  <div class="what-list">
    <div class="container">
      <div class="what-list__box">
        <div class="what-list__bg"></div>
        <div class="what-list__content">
          <div class="what-list__top" data-aos="fade-up">
            <div class="what-list__title title">В какой форме <br>могут проявляться <br>домогательства?</div>
            <div class="what-list__image">
              <img src="../assets/img/what.jpg" alt="">
            </div>
          </div>
          <div class="what-list__info" data-aos="fade-up">
            <ul>
              <li>Нежелательные сексуальные предложения или прикосновения.</li>
              <li class="just-mobile">намеки на секс или принуждение к нему.</li>
              <li class="just-desktop">Принуждение к сексу.</li>
              <li class="just-desktop">Намеки на секс.</li>
              <li class="just-desktop">Непристойное поведение.</li>
              <li>Сексуализированные комментарии.</li>
              <li>Оскорбительные шутки.</li>
            </ul>
            <ul>
              <li class="just-desktop">Унизительные замечания.</li>
              <li>Свист на улице вслед.</li>
              <li class="just-mobile">предъявление унижающих изображений (или обнажение перед жертвой домогательств) <br>и другие.</li>
              <li class="just-desktop">Физические жесты.</li>
              <li class="just-desktop">Выражение лица (например, “раздевающий” взгляд).</li>
              <li class="just-desktop">Предъявление унижающих изображений <br>(или обнажение перед жертвой <br>домогательств).</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block3',
}
</script>


<style lang="sass">
  .what-list
    height: 673px
    margin-top: 102px
    &__content
      position: relative
      z-index: 2
    &__box
      position: relative
    &__bg
      position: absolute
      z-index: 1
      &:before
        content: ''
        display: block
        height: 673px
        width: 100vw
        background-color: #FE553A
        position: absolute
      &:after
        content: ''
        width: 500px
        height: 673px
        background-color: #FE553A
        display: block
        margin-left: -261px
        position: absolute
        transform: skew(-11deg)
    &__top
      display: flex
      justify-content: space-between
    &__image
      width: 534px
      margin-right: 7px
      margin-top: -102px
      img
        width: 100%
    &__title
      color: #fff !important
      margin-top: 102px
      margin-left: -4px
    &__content
      padding-left: 4px
    &__info
      display: flex
      justify-content: space-between
      margin-top: 67px
      margin-left: -4px
      ul
        width: 540px
        li
          font-size: 18px
          font-weight: 500
          color: #fff
          line-height: 26px
          text-transform: uppercase
          display: flex
          letter-spacing: 0px
          margin-bottom: 12.8px
          text-indent: 15px !important
          display: block
          &:before
            content: ''
            min-width: 6px
            width: 6px
            height: 6px
            background-color: #fff
            border-radius: 50%
            display: block
            margin-top: 10px
            margin-right: 10px
            position: absolute
          &.just-mobile
            display: none

  @media screen and (max-width: 1000px)
    .what-list__image
      width: 100%
      min-width: 100%
      margin-right: 0
      margin-top: vw(-135px)
      order: 1
    .what-list__top
      flex-direction: column
    .what-list__title
      order: 2
      margin-top: vw(27px)
      margin-left: 0
    .what-list__content
      padding-left: 0
    .what-list
      margin-top: vw(176px)
      height: vw(593px)
    .what-list__bg:after
      width: 0
      height: 0
    .what-list__bg:before
      margin-left: vw(-15px)
      height: vw(593px)
    .what-list__info
      flex-direction: column
      margin-top: vw(32px)
      margin-left: 0
    .what-list__info ul li
      font-size: vw(15px)
      line-height: vw(20px)
      margin-bottom: vw(11.8px)
      &.just-desktop
        display: none
      &.just-mobile
        display: block
    .what-list__info ul
      width: 100%
    .what-list__info ul li:before
      width: vw(5px)
      min-width: vw(5px)
      height: vw(5px)
      margin-top: vw(4px)
</style>
