<template>
  <div class="intro">
    <div class="container">
      <div class="intro__content">
        <div class="intro__image" data-aos="fade-left">
          <img src="../assets/img/intro.jpg" alt="">
        </div>
        <div class="intro__info">
          <div class="intro__title title" data-aos="fade-up">Что такое <br>сексуальные <br>домогательства?</div>
          <div class="intro__auth" data-aos="fade-up">
            <div class="intro__auth-icon">
              <img src="../assets/img/avatar.jpg" alt="">
            </div>
            <div class="intro__auth-text">
              <div class="intro__auth-title">Наира Парсаданян</div>
              <div class="intro__auth-label">психолог и консультант по вопросам насилия</div>
            </div>
          </div>
          <div class="intro__text text" data-aos="fade-up">
            <p>Сексуальные домогательства — любые нежелательные вербальные, невербальные или физические действия сексуального характера. Люди (в основном речь про женщин) нередко подвергаются им в публичных пространствах, например, на улице, в магазине, в кафе.</p>
            <p class="just-desktop">Домогательства входят в более широкое понятие «харассмент» — поведение, унижающее личность и достоинство человека, и считаются сексуализированным насилием.</p>
            <p class="just-desktop">Те, кто домогается незнакомых людей на улицах, движимы не желанием секса: скорее они (пусть и неосознанно) хотят почувствовать и обрести власть и контроль.</p>
            <p class="just-mobile">Те, кто домогается незнакомых людей на улицах, движимы не желанием секса: скорее они (пусть и неосознанно) хотят почувствовать и обрести власть и контроль, подчеркивает психолог и консультант по вопросам насилия Наира Парсаданян.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block2',
}
</script>


<style lang="sass">
  .intro
    margin-top: 73px
    position: relative
    z-index: 11
    &__content
      display: flex
      justify-content: space-between
    &__image
      width: 585px
      min-width: 585px
      margin-left: -48px
      margin-top: 13px
      img
        width: 100%
    &__title
      margin-bottom: 54px
    &__auth
      display: flex
      align-items: center
      &-icon
        width: 100px
        margin-right: 22px
        img
          width: 100%
      &-title
        font-size: 18px
        font-weight: 500
        color: #FE553A
        text-transform: uppercase
        margin-bottom: 8px
      &-label
        font-size: 13px
        letter-spacing: 0px
        color: #626466
    &__text
      width: 519px
      margin-top: 51px
      letter-spacing: 0.03px !important
      line-height: 22.5px !important
      p
        margin-bottom: 9px
        &.just-mobile
          display: none
    &__info
      padding-right: 23px

  @media screen and (max-width: 1000px)
    .intro__content
      flex-direction: column
    .intro__image
      width: 100%
      min-width: 100%
      margin-left: 0
      margin-bottom: vw(27px)
    .intro
      margin-top: vw(37px)
    .intro__auth-icon
      width: vw(90px)
      min-width: vw(90px)
      margin-right: vw(20px)
    .intro__title
      margin-bottom: vw(30px)
    .intro__auth-title
      font-size: vw(16px)
      margin-bottom: vw(6px)
    .intro__auth-label
      font-size: vw(14px)
      line-height: vw(15px)
      padding-right: vw(20px)
    .intro__text
      width: calc(100% + 30px)
      margin-top: vw(26px)
      line-height: vw(20.1px) !important
      letter-spacing: normal !important
      padding-right: 4px
      p
        margin-bottom: vw(9px)
        &.just-desktop
          display: none
        &.just-mobile
          display: block
</style>
