<template>
  <div class="test">
    <div class="container">
      <div class="test__content">
        <div class="test__info">
          <div class="test__close" @click="$emit('close')"></div>
          <div class="test__title"><slot name="question"></slot></div>
          <div class="test__pagination">{{step}}/5</div>
        </div>
        <div class="test__answer">
          <div class="test__answer-title"><slot name="title"></slot></div>
          <div class="test__answer-text">
            <slot name="text"></slot>
          </div>
          <div class="test__next">
            <a href="javascript:void(0)" @click="$emit('next')"></a>
          </div>
          <div class="test__next test__next--mobile">
            <a href="javascript:void(0)" @click="$emit('next')">Дальше</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block7',
  props: {
    step: Number,
  }
}
</script>


<style lang="sass">
  .test
    &__answer
      width: 400px
      margin-left: 156px
      margin-top: 0
      position: relative
      &-title
        font-size: 18px
        color: #FE553A
        font-weight: 500
        text-transform: uppercase
        margin-bottom: 23px
      &-text
        font-size: 15px
        line-height: 22px
        color: #181818
    &__next
      position: absolute
      right: -5px
      bottom: 37px
      a
        background-image: url(../assets/img/arrow.svg)
        background-size: 27px
        background-repeat: no-repeat
        background-position: 50% 50%
        width: 57px
        height: 57px
        border-radius: 50%
        background-color: #fff
        border: 1.5px solid #FE553A
        display: inline-block
        transition: all 150ms
        &:hover
          background-image: url(../assets/img/arrow-white.svg)
          background-color: #FE553A
      &--mobile
        display: none

  @media screen and (max-width: 1000px)
    .test__answer
      margin-left: 0
      width: 100%
      text-align: center
    .test__answer
      margin-top: vw(30px)
      height: 100%
    .test__answer-title
      font-size: vw(20px)
      margin-bottom: vw(19px)
    .test__answer-text
      font-size: vw(14px)
      line-height: vw(18px)
      width: vw(300px)
      margin: auto
      br
        display: none
      span
        &.just-desktop
          display: none
    .test__next
      display: none
      &--mobile
        display: block
        bottom: vw(20px)
        right: 0
        left: 0
        margin: auto
    .test__next a
      background-image: none
      font-size: vw(14px)
      color: #000
      font-family: 'Gotham Pro'
      text-decoration: none
      font-weight: 500
      width: vw(200px)
      height: vw(45px)
      line-height: vw(44px)
      border-radius: 50px
      &:hover
        background-image: none
  @media screen and (min-width: 1000px)
    .test__answer
      display: flex
      flex-direction: column
      justify-content: center
</style>

<style lang="sass" scoped>
  @media screen and (max-width: 1000px)
    .test__pagination
      top: vw(20px)
      font-size: vw(16px)
    .test__title
      font-size: vw(14px)
      line-height: vw(18px)
      margin-top: vw(20px)
      letter-spacing: -0.1px
      padding-left: vw(10px)
      padding-right: vw(10px)
    .test__info
      height: auto
      padding-bottom: vw(25px)
      padding-top: vw(36px)
</style>